import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react"

const EyewearCollections = () => {
  const collections = useStaticQuery(
    graphql`
      query {
        allWpEyewearCollection(
          sort: {order: ASC, fields: eyewearCollectionFields___order}
        ) {
          nodes {
            id
            name
            description
            uri
            eyewearCollectionFields {
              collectionImage {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 500
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }      
    `
  )

  const columnAlignments = {
    columns_2: {
      style_col_1: "-mt-10 mb-10",
      style_col_2: "mt-16 -mb-16",
    },
    columns_3 : {
      style_col_1: "md:-mt-32 md:mb-32",
      style_col_2: "md:mt-16 md:-mb-16",
      style_col_3: "md:-mt-10 md:mb-10",
    },
    columns_5 : {
      style_col_1: "lg:-mt-32 lg:mb-32",
      style_col_2: "lg:mt-16 lg:-mb-16",
      style_col_3: "lg:-mt-10 lg:mb-10",
      style_col_4: "lg:mt-40 lg:-mb-40",
      style_col_5: "lg:-mt-20 lg:mb-20",
    },
  }

 
  // <div className="grid group z-10 -mt-10 mb-10 md:-mt-32 md:mb-32">
  // <div className="grid group z-10 mt-16 -mb-16 md:mt-16 md:-mb-16">
  // <div className="grid group z-10 -mt-10 mb-10 md:-mt-10 md:mb-10">
  // <div className="grid group z-10 mt-16 -mb-16 md:-mt-32 md:mb-32 lg:mt-40 lg:-mb-40">
  // <div className="grid group z-10 -mt-10 mb-10 md:mt-16 md:-mb-16 lg:-mt-20 lg:mb-20">

  return (
    <div className="col-span-full relative grid grid-cols-[repeat(2,auto)] md:grid-cols-[repeat(3,auto)] lg:grid-cols-[repeat(5,auto)] auto-rows-fr gap-4 justify-between mt-20 mb-32 after:block after:bg-tdb-section-bg after:-bottom-24 after:-right-4 after:z-0 after:absolute after:w-3/5 after:h-3/4">
      { collections.allWpEyewearCollection.nodes.map((collection, i) =>
        <div className={"grid group z-10 " + columnAlignments.columns_2['style_col_' + ((i % 2) + 1)] + " " + columnAlignments.columns_3['style_col_' + ((i % 3) + 1)] + " " + columnAlignments.columns_5['style_col_' + ((i % 5) + 1)]} key={collection.id}>
          <div className="col-span-full row-span-full z-10 overflow-hidden transition duration-300 ease-in-out group-hover:bg-black group-hover:bg-opacity-20">
            <Link to={collection.uri} title={collection.description + "..."}>
              <GatsbyImage image={getImage(collection.eyewearCollectionFields.collectionImage.localFile)} alt={collection.description + "..."} className="w-full h-full transform transition duration-300 ease-in-out group-hover:scale-110 group-hover:duration-300 mix-blend-overlay"/>
            </Link>
          </div>
          <Link to={collection.uri}  title={collection.description + "..."} className="button col-span-full row-span-full self-end bg-tdb-light bg-opacity-75 text-white mx-4 mb-4 z-10 group-hover:bg-opacity-100 group-hover:duration-300">
            {collection.name}
          </Link>
        </div>
      )}
    </div>
  )
};

export default EyewearCollections;