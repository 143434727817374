import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import EyewearCollections from "../components/eyewear_collections"
import InstagramFeed from "../components/instagram_feed"
import Layout from "../components/layout"
// import Popup from "../components/popup"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <section className="hero">
      <div className="hero-content">
        <h1>Optiek Tom De Bruyne</h1>
        <h2>Eén doel voor ogen</h2>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">Bij Optiek Tom De Bruyne hebben we slechts één doel voor ogen: het bieden van de beste oogzorg voor iedereen. Daarbij kijken we verder dan alleen het uitzoeken van een 'mooi montuur'.</p>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">Ons team bestaat enkel uit gediplomeerde opticiens, optometristen, contactlensspecialisten. Door al deze kennis kunnen we werkelijk alles bieden op het gebied van oogzorg.</p>
        <p className="w-1/2 md:w-8/12 lg:w-1/2 my-4">U wenst voor uw ogen toch ook enkel het beste?</p>
        <div className="flex flex-wrap w-8/12 lg:w-1/2 mt-16 ">
          <Link to="/contact" className="button bg-tdb-light hover:bg-tdb-dark text-white mr-2" title="Contacteer ons voor een afspraak ...">Afspraak maken</Link>
        </div>
      </div>
      <StaticImage
          src="../images/hero_1.jpg"
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Optiek Tom Debruyne"
          placeholder="blurred"
          className="hero-img"
      />
      <div className="hero-bg"></div>
    </section>
    <section className="section">
      <h2 className="col-span-full text-center z-20">Eyewear</h2>
      <EyewearCollections />
    </section>
    <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Oogtest
        </h2>
        <p>U kan bij ons een afspraak maken voor een oogtest onder uiterst professionele begeleiding. Wij werken enkel met gediplomeerde opticiens en optometristen.</p>
        <p>Er wordt gebruik gemaakt van de oogscanner zodat alles precies tot op 1/100 nauwkeurig wordt opgemeten. Zo krijgt u het beste resultaat</p>
        <div className="flex flex-wrap mt-10 ">
          <Link to="/diensten" title="Onze diensten ..." className="button bg-transparent text-tdb-light hover:text-tdb-dark px-0 py-0">{"Onze diensten ->"}</Link>
        </div>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/oogmeting_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
        <StaticImage
            src="../images/oogmeting_2.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden lg:opacity-100 lg:inline-block mx-2 w-4/12 h-1/2"
        />
      </div>
      <div className="section-bg-left"></div>
    </section>
    <section className="section">
      <h2 className="col-span-full text-center z-20">
        Social
      </h2>
      <h4 className="col-span-full text-center z-20">
        <a href="https://www.instagram.com/optiek_tom_de_bruyne/" target="_blank" rel="noreferrer" className="text-tdb-light">@optiek_tom_de_bruyne</a>
      </h4>
      <InstagramFeed />
      {/* <div className="section-bg-right"></div> */}
    </section>
    {/* <Popup description="test" title="titel"/> */}
  </Layout>
)

export default IndexPage
